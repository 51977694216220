

































































































































import {Component, Prop, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import CreatePersonRequestDTO from "@/dto/person/CreatePersonRequestDTO";
import PhoneInput from "@/components/util/PhoneInput.vue";
import PersonService from "@/services/PersonService";
import ErrorLocalePathUtils from "@/utils/ErrorLocalePathUtils";
import PersonDTO from "@/dto/person/PersonDTO";
import AdminLinksModal from "@/components/profile/admin/AdminLinksModal.vue";
import {fromPerson} from "@/dto/auth/Workspace";
import CustomerAccounts from "@/dto/CustomerAccounts";
import MergeAccountModal from "@/components/profile/admin/MergeAccountModal.vue";
import MergePersonSsnModal from "@/components/profile/admin/person/MergePersonSsnModal.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import PortalInput from "@/components/common/PortalInput.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import {ifValid, processError} from "@/utils/ComponentUtils";
import RouteNames from "@/router/RouteNames";
import {debounce} from "typescript-debounce-decorator";
import PersonFilterDTO from "@/dto/person/PersonFilterDTO";

const AppModule = namespace("App");
const Auth = namespace("Auth");

@Component({
	methods: {
		RouteNames() {
			return RouteNames
		}
	},
	components: {PortalCheckbox, PortalInput, PhoneInput}
})
export default class PersonCreationComponent extends Vue {
	private message = "";

	private successful = false;

	@AppModule.Action
	private startLoading!: () => void

	@AppModule.Action
	private stopLoading!: () => void

	@Prop()
	private onSuccess!: () => void

	private request = new CreatePersonRequestDTO()

	private shouldNotCreateAccount = false

	private existName: Array<PersonDTO> = []
	private existSSN: Array<PersonDTO> = []
	private existEmail: Array<PersonDTO> = []


	get isPhoneFilled() {
		return (this.request.phone != undefined || this.request.phone != null) && this.request.phone != "" && this.request.phone.length >= 7;
	}

	save() {
		ifValid(this, () => {
			if (this.request.phone != null) {
				if (7 > this.request.phone.length) {
					this.request.phone = null;
				}
			}
			this.startLoading()
			this.message = ""
			this.request.email = this.request.email?.trim() == '' ? null : this.request.email;
			if (this.shouldNotCreateAccount) {
				this.request.email = '';
			}
			PersonService.registerCustomer(this.request).then(
				() => this.handleSuccess(),
				err => this.errorHandling(err)
			)
		})
	}

	errorHandling(err: any) {
		this.successful = false;
		let systemCode = err?.response?.data.systemCode;
		let param = err?.response?.data.param;
		let person: PersonDTO;
		let customerAccounts: CustomerAccounts;
		this.$modal.hide("hide")
		switch (systemCode) {
			case 40023:
			case 40033:
				person = param;
				if (person.email == null && this.request.email != null) {
					this.openMergePersonSsnModal(person);
				} else {
					this.openLinksModal(person);
				}
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(err),
					{
						ssn: person.ssn,
						lastName: person.lastName,
						firstName: person.firstName,
						tin: person.nationalTin
					}) as string;
				break;
			case 40025:
				customerAccounts = param;
				this.openMergeAccountModal(customerAccounts)
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(err),
					{
						lastName: customerAccounts.person?.lastName,
						firstName: customerAccounts.person?.firstName,
						listCompanyNames: this.handleCompaniesName(customerAccounts.companies)
					}) as string;
				break
			default:
				this.message = this.$t(ErrorLocalePathUtils.getPathForError(err), param) as string;
		}
		this.stopLoading();
	}

	handleCompaniesName(companies: Array<CompanyDTO>): string {
		let res = "";
		for (let i = 0; i < companies.length; i++) {
			res += companies[i].name.concat(i == companies.length - 1 ? "" : ", ");
		}
		return res;
	}

	handleSuccess() {
		this.onSuccess();
		this.stopLoading();
		this.$modal.hideAll()
	}

	openMergePersonSsnModal(existPerson: PersonDTO) {
		this.$modal.show(
			MergePersonSsnModal,
			{
				request: this.request,
				existPerson: existPerson,
				onSuccess: () => this.handleSuccess()
			}
		);
	}

	openMergeAccountModal(customerAccounts: CustomerAccounts) {
		this.$modal.show(
			MergeAccountModal,
			{
				customerAccounts: customerAccounts,
				personReq: this.request,
				onSuccess: () => this.handleSuccess()
			}
		);
	}

	openLinksModal(person: PersonDTO) {
		this.$modal.show(
			AdminLinksModal,
			{
				workspace: fromPerson(person),
				name: person.lastName?.concat(" ").concat(person.firstName as string)
			}
		);
	}

	get isExistName(): boolean {
		return this.existName.length > 0
	}

	get isExistEmail(): boolean {
		return this.existEmail.length > 0
	}

	get isExistSSN(): boolean {
		return this.existSSN.length > 0
	}

	@debounce(200)
	search(){
		if ((this.request.lastName && this.request.lastName.length > 2) || this.request.firstName && this.request.firstName.length > 2) {
			PersonService.getByFilter(
				new PersonFilterDTO({
					firstName: this.request.firstName,
					lastName: this.request.lastName,
					pageSize: 5
				})
			).then(
				ok => {
					this.existName = ok.data.data;
				},
				err => processError(this, err)
			)
		}
		if (this.request.ssn && this.request.ssn.length > 2) {
			PersonService.getByFilter(
				new PersonFilterDTO(
					{
						ssn: this.request.ssn,
						pageSize: 5
					}
				)
			).then(
				ok => {
					this.existSSN = ok.data.data;
				},
				err => processError(this, err)
			)
		}
		if (this.request.email && this.request.email.length > 2) {
			PersonService.getByFilter(
				new PersonFilterDTO(
					{
						email: this.request.email,
						pageSize: 5
					}
				)
			).then(
				ok => {
					this.existEmail = ok.data.data;
				},
				err => processError(this, err)
			)
		}
	}
}
